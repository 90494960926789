import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/generic/page-title"

import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import ContactForm from "../components/generic/contact-form"

const Services = () => {
  const data = useStaticQuery(query)
  const stockImage = data.stockImage.childImageSharp.fluid
  const dbaName = data.name.siteMetadata.company.name.dba

  return (
    <Layout>
      <SEO
        title="Services"
        description="We can help make your business more secure, more productive, and ready to grow. See what we offer."
      />
      <PageTitle title="Services" />
      <div className="page-services">
        <div className="page-services__content-container">
          <div className="page-services__overview-content">
            <div className="page-services__services-page-heading">
              <h2 className="heading-secondary heading-secondary--heading-underline-thin">
                <em>Services</em> Overview &amp; <em>Membership</em> Packages
              </h2>
            </div>
            <div className="page-services__overview-content-text">
              <p className="paragraph">
                We at {dbaName}{" "}
                <em>choose to deliver an elite managed IT solution</em> to our
                member companies. We provide our member companies who trust us
                with exactly what they need to{" "}
                <em>scale effectively with cost efficiency</em>. This{" "}
                <em>
                  makes us different from the vast majority of IT managed
                  service provider's (MSP)
                </em>{" "}
                in that this type of mutually beneficial business relationship
                requires a high degree of <em>trust</em> since{" "}
                <em>this is a no-haggle business arrangement</em>. When you
                bring us on,{" "}
                <em>
                  we build out your IT infrastructure to fit industry
                  best-practices and your specific needs
                </em>{" "}
                based on our expert knowledge.{" "}
                <em>
                  We also require our member companies to comply with minimum
                  infrastructure standards
                </em>{" "}
                so that we can deliver the service we promise.
              </p>
              <p className="paragraph">
                This leaves us with <em>a very simple service structure</em>. We
                have two options for the membership service agreement. One with
                unlimited on-site visits (Gold) and one without (Standard). Each
                service option comes at{" "}
                <em>
                  a fixed rate per user, per month making budgeting and
                  forecasting simple and predictable
                </em>
                .
              </p>
            </div>
            <table className="page-services__service-features-table">
              <thead>
                <tr>
                  <td className="page-services__service-features-head-data">
                    Membership Feature
                  </td>
                  <td className="page-services__service-features-head-data page-services__service-features-head-data--center">
                    Standard
                  </td>
                  <td className="page-services__service-features-head-data page-services__service-features-head-data--center page-services__service-features-head-data--gold">
                    Gold
                  </td>
                </tr>
              </thead>
              <tbody>
                {featuresComparisonList.map(f => {
                  return (
                    <tr
                      className="page-services__service-features-data-row"
                      key={f.key}
                    >
                      <td
                        className="page-services__service-features-data"
                        key={f.key + "Name"}
                      >
                        {f.name}
                      </td>
                      <td
                        className="page-services__service-features-data page-services__service-features-data--center"
                        key={f.key + "Standard"}
                      >
                        {f.present.standard ? (
                          <span className="page-services__service-feature-present">
                            &#10004;
                          </span>
                        ) : (
                          <span className="page-services__service-feature-absent">
                            &#10008;
                          </span>
                        )}
                      </td>
                      <td
                        className="page-services__service-features-data page-services__service-features-data--center"
                        key={f.key + "Gold"}
                      >
                        {f.present.gold ? (
                          <span className="page-services__service-feature-present page-services__service-feature-present--gold">
                            &#10004;
                          </span>
                        ) : (
                          <span className="page-services__service-feature-absent">
                            &#10008;
                          </span>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="page-services__services-page-heading">
              <h2 className="heading-secondary heading-secondary--heading-underline-thin">
                <em>Onboarding</em> Information
              </h2>
            </div>
            <div className="page-services__overview-content-text">
              <p className="paragraph">
                <em>Onboarding can be a challenging process</em>. We've added
                member companies whose IT infrastructure was so aged and
                technologically flawed they're practically being re-built from
                the ground up. And, it can take quite some time to fully onboard
                a company in such a state. However,{" "}
                <em>
                  no matter the challenge, the onboarding process is handled in
                  a thorough, task-based style with the same high quality for
                  all member companies and at the same fixed rate per user
                </em>
                .
              </p>
            </div>
            <div className="page-services__services-page-heading">
              <h2 className="heading-secondary heading-secondary--heading-underline-thin">
                New <em>Projects</em>
              </h2>
            </div>
            <div className="page-services__overview-content-text">
              <p className="paragraph">
                It's common for companies to need to upgrade their IT
                infrastructure to meet demand over time. And, it's common for
                new member companies to need to upgrade their IT infrastructure
                to meet our well-defined minimum acceptable state to engage in a
                mutually beneficial business partnership where we act as your
                MSP.{" "}
                <em>
                  While we do not provide new hardware and software products and
                  services as part of the membership, we make projects easy
                </em>
                . You'll get a{" "}
                <em>
                  clearly-defined and complete statement of work with a fixed
                  quote when new projects are required
                </em>
                . <em>We also partner with industry-leaders in IT</em>, and can
                provide you with{" "}
                <em>excellent equipment and software at a great price</em>.
              </p>
            </div>
            <div className="page-services__contact-form-container">
              <ContactForm>
                <h2 className="heading-secondary">Learn More</h2>
                <p className="paragraph">
                  If you've got questions about our services or package, please
                  get in contact with us and we'll give you all the information
                  you need to make an informed decision.
                </p>
              </ContactForm>
            </div>
          </div>
          <div className="page-services__overview-content-image">
            <Img fluid={stockImage} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Services

const query = graphql`
  query {
    stockImage: file(relativePath: { eq: "packages-stock-model.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    name: site {
      siteMetadata {
        company {
          name {
            dba
          }
        }
      }
    }
  }
`
const featuresComparisonList = [
  {
    key: "umlimitedRemote",
    name: "Unlimited Remote",
    present: {
      gold: true,
      standard: true,
    },
  },
  {
    key: "unlimitedOnSite",
    name: "Unlimited On Site Visits",
    present: {
      gold: true,
      standard: false,
    },
  },
  {
    key: "24x7monitoring",
    name: "24x7 Monitoring",
    present: {
      gold: true,
      standard: true,
    },
  },
  {
    key: "security",
    name: "Security",
    present: {
      gold: true,
      standard: true,
    },
  },
  {
    key: "pci3.2.1_saq_c",
    name: "Payment Card Industry (PCI) Standards",
    present: {
      gold: true,
      standard: true,
    },
  },
  {
    key: "analytics",
    name: "Analytics",
    present: {
      gold: true,
      standard: true,
    },
  },
  {
    key: "memberPortal",
    name: "Member Portal",
    present: {
      gold: true,
      standard: true,
    },
  },
  {
    key: "office365",
    name: "Office 365 Support",
    present: {
      gold: true,
      standard: true,
    },
  },
  {
    key: "mobileDeviceManagement",
    name: "Mobile Device Management",
    present: {
      gold: true,
      standard: true,
    },
  },
  {
    key: "serverManagement",
    name: "Server Management",
    present: {
      gold: true,
      standard: true,
    },
  },
  {
    key: "workstationManagement",
    name: "Workstation Management",
    present: {
      gold: true,
      standard: true,
    },
  },
  {
    key: "networkDeviceManagement",
    name: "Network Device Management",
    present: {
      gold: true,
      standard: true,
    },
  },
  {
    key: "automatedBackupSolutions",
    name: "Automated Backup Solutions",
    present: {
      gold: true,
      standard: true,
    },
  },
  {
    key: "reporting",
    name: "Reporting",
    present: {
      gold: true,
      standard: true,
    },
  },
  {
    key: "nistControls",
    name: "NIST Control Policies",
    present: {
      gold: true,
      standard: true,
    },
  },
  {
    key: "soc2Compliance",
    name: "SOC Compliance",
    present: {
      gold: true,
      standard: true,
    },
  },
  // {
  //   key: "microsoftPartnership",
  //   name: "Microsoft Partnership",
  //   present: {
  //     gold: true,
  //     standard: true,
  //   },
  // },
  // {
  //   key: "dattoPartnership",
  //   name: "Datto Partnership",
  //   present: {
  //     gold: true,
  //     standard: true,
  //   },
  // },
  // {
  //   key: "ciscoPartnership",
  //   name: "Cisco Partnership",
  //   present: {
  //     gold: true,
  //     standard: true,
  //   },
  // },
  // {
  //   key: "polycomPartnership",
  //   name: "Polycom Partnership",
  //   present: {
  //     gold: true,
  //     standard: true,
  //   },
  // },
  // {
  //   key: "vmwarePartnership",
  //   name: "VMWare Partnership",
  //   present: {
  //     gold: true,
  //     standard: true,
  //   },
  // },
  // {
  //   key: "apcPartnership",
  //   name: "APC Partnership",
  //   present: {
  //     gold: true,
  //     standard: true,
  //   },
  // },
]
