import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const ContactForm = ({ children }) => {
  const data = useStaticQuery(query)
  const email = data.site.siteMetadata.company.contact.email
  const siteThankYouUrl = data.site.siteMetadata.siteThankYouUrl

  const utm = {
    source: "Form",
    medium: "Organic",
    campaign: "None",
  }

  const redirectUrl = `${siteThankYouUrl}?utm_source=${utm.source}&utm_medium=${utm.medium}&utm_campaign=${utm.campaign}`

  return (
    <div className="contact-form">
      {children}
      <form
        className="contact-form__form"
        action={"https://formspree.io/" + email}
        method="POST"
        name="contact-form"
      >
        <input name="_next" type="hidden" value={redirectUrl} />
        <input name="campaign_id" type="hidden" value="None" readOnly />
        <input name="name" type="text" placeholder="Name" />
        <input name="email" type="email" placeholder="Email@Domain.com" />
        <input name="phone" type="tel" placeholder="Phone" />
        <textarea
          name="message"
          id="message"
          cols="30"
          rows="10"
          placeholder="Message..."
        ></textarea>
        <div className="contact-form__button">
          <button
            type="submit"
            formMethod="POST"
            className="button button--primary"
          >
            Send Your Message
          </button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm

const query = graphql`
  query {
    site {
      siteMetadata {
        siteThankYouUrl
        company {
          contact {
            email
          }
        }
      }
    }
  }
`
